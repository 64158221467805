import React from 'react'
import { graphql } from 'gatsby'
import ContentContainer from '../components/UI/ContentContainer'
import styled from 'styled-components'
import { FaChevronLeft } from 'react-icons/fa'
import Layout from '../layouts/layout'
import Seo from '../components/seo'

const FaqPageTemplate = ({ data }) => {
  const {
    question,
    answer,
    seoMetaTitle,
    seoMetaDescription,
    seoMetaImage
  } = data.contentfulComponentFaQs


  return (
    <Layout>
      <Seo title={seoMetaTitle} description={seoMetaDescription} image={seoMetaImage?.fluid?.src} />
      <main>
        <ContentContainer maxWidth="960px">
          <FaqWrapper>
            <FaqLink href="/marketplace/help-spendsave/" >
              <div className="icon">
                <FaChevronLeft />
              </div>
              <p>See All FAQs</p>
            </FaqLink>
            <h1>{question}</h1>
            <div className="faq"
              dangerouslySetInnerHTML={{
                __html: answer.childMarkdownRemark.html
              }}
            />
          </FaqWrapper>
        </ContentContainer>
      </main>
    </Layout>
  )
}

const FaqWrapper = styled.div`
h1 {
  margin-top:30px;
}

.faq {
  margin-top: 8px;
  margin-bottom: 8px;
  padding-top: 16px;
  border-top: 1px solid #d4dbe0;
}
`

const FaqLink = styled.a`
	display: flex;
	p {
		margin: 5px 0;
	};
	.icon {
		display: flex; 
		align-items: center;
	};
`

export default FaqPageTemplate

export const pageQuery = graphql`
  query FaqPage(
    $slug: String!
  ) {
    contentfulComponentFaQs(slug: { eq: $slug }) {
      question
      answer {
        childMarkdownRemark {
          html
        }
      }
      seoMetaTitle
      seoMetaDescription
      seoMetaImage {
        fluid {
          src
        }
      }
    }    
  }
`
